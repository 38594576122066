import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background:var(--white);

    .section-image-text{
    width: 100%;
    padding:4rem 2rem;
    display: flex;
    max-width: 1500px;
    margin:0 auto;
    flex-wrap: wrap;
    align-items:flex-start;

        @media only screen and (min-width: 350px) {
        padding:4rem;
        }

        @media only screen and (min-width: 960px) {
        flex-wrap: nowrap;
        padding:8rem;
        }

        .text-wrapper{
        width: 100%;
        padding: 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        order:1;
        margin-bottom: 4rem;

            @media only screen and (min-width: 960px) {
            margin-bottom: 0;
            order:2;
            width: 50%;
            padding: 0 0 0 4rem;
            }
            
            @media only screen and (min-width: 1024px) {
            padding: 0 0 0 8rem; 
            }
        
            @media only screen and (min-width: 1100px) {
            width: 60%;
            }

            p{
            margin-bottom: 2rem;
            font-size:1.6rem;
            line-height:2.5rem;
            width: 100%;

                &:last-of-type{
                margin-bottom: 0 !important;
                }

            }

        }

        .image-wrapper{
        width: 100%;
        align-self:stretch;
        order:2;

            @media only screen and (min-width: 960px) {
            order:1;
            width: 50%;
            margin-bottom: 4rem;
            margin-bottom: 0;
            }

            @media only screen and (min-width:961px) {
            width: auto;
            }


            .gatsby-image-wrapper{
            width: 100%;
            height: 0 !important;
            padding-bottom: 100%;
            
                @media only screen and (min-width: 650px) {
                padding-bottom: 50%;
                }

                @media only screen and (min-width: 960px) {
                height: 100% !important;
                padding-bottom: 0;
                }

            }

        }

        &.section-1{
        padding-top:4rem;
        padding-bottom: 0;

            @media only screen and (min-width: 960px) {
            padding-top:8rem;
            }
            
            @media only screen and (min-width: 1024px) {
            padding-top:12rem;
            }
        
            p{
                &:first-child{
                text-transform: uppercase;
                font-size:2.5rem;
                line-height:3rem;
                }

            }
        }

        &.section-2{

            @media only screen and (min-width: 350px) {
            padding-bottom: 0;
            }
            
            @media only screen and (min-width: 1024px) {
            padding-bottom: 8rem;
            }



            .image-wrapper{
            order:2;
            width: 100%;
            margin-bottom: 0;

                @media only screen and (min-width: 960px) {
                order:2;
                width: 50%;
                margin-bottom: 4rem;
                }
    
                @media only screen and (min-width: 1100px) {
                width: 40%;
                }

                .gatsby-image-wrapper{
                height:100%;
                }

            }

            .text-wrapper{
            width: 100%;
            padding: 0;
            order:1;

                @media only screen and (min-width: 960px) {
                width: 60%;
                padding: 0 4rem 0 0;
                order:1;
                }
            

                @media only screen and (min-width: 1024px) {
                padding: 0 8rem 0 0; 
                }
            }
        }
    }
    
    /* .section-2{
    width: 100%;
    padding:8rem;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top:-5%;

        .image-wrapper{
        width: 40%;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;

            .gatsby-image-wrapper{
            height:100%;
            }

        }

        .text-wrapper{
        width: 60%;
        padding: 0 10% 0 8rem;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
            

            p{
            width: 100%;
            margin-bottom: 4rem;
            line-height: 1.5em;
            font-size:clamp(1.7rem, 1.3vw, 2.8rem);
    
                &:last-child{
                margin-bottom: 0;
                }


            }

        }

    } */

    .section-3{
    width: 100%;
    padding:4rem 2rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:-5%;
    flex-wrap: wrap;

        @media only screen and (min-width: 350px) {
        padding:8rem 4rem 4rem 4rem;
        }

        @media only screen and (min-width: 960px) {
        padding:8rem;
        }

        .listItem{
        width:100%;
        padding:4rem 0;
        border-bottom: 1px solid var(--grey_light);
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;

            @media only screen and (min-width: 768px) {
            flex-wrap: nowrap;
            }

            &:first-child{
                border-top: 1px solid var(--grey_light);
            }

            &:last-child{
                border-bottom:0;
            }

            .text,
            .title{
            width:100%;

                @media only screen and (min-width: 768px) {
                width:50%;
                }
            }

            .text{
            line-height: 1.5em;
            font-size:clamp(1.4rem, 1vw, 2rem);
            }

            .title{
                font-size:clamp(2.2rem, 3vw, 4rem);
                text-transform: uppercase;
                letter-spacing: -0.07em;
                ${({theme}) => theme.tomato('regular')}  
                margin-bottom: 2rem;

                @media only screen and (min-width: 768px) {
                margin-bottom: 0;
                }

            }

        }
   
    }


`

export { Styling }