import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"


const Seo = ({ description, lang, meta, title }) => {
 
    const metaDescription = description
    const seoTitle = title
  
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={seoTitle}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: seoTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
       
          {
            name: `twitter:title`,
            content: seoTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
    )
  }
  
  Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
  }
  
  Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
  }
  
  export default Seo
  